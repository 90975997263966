import { ApolloError } from '@apollo/client/errors'

import Money from '~/models/Money'

export const useRedeemMoney = () => {
  const auth = useAuth()
  const route = useRoute()
  const isClient = useIsClient()

  const { addToast } = useToaster()
  const { mutate } = useRedeemMoneyMutation()
  const { t } = useI18n()

  const redeemMoney = async () => {
    let amount: Maybe<Money> = null

    try {
      if (!route.query.redeem) return amount
      if (!auth.isLoggedIn.value) {
        navigateTo({
          path: '/login',
          query: {
            redirect: useRoute().fullPath,
          },
        })
        return
      }

      const result = await mutate({
        input: { code: String(route.query.redeem) },
      })
      const price = result?.data?.requestMoneyBack.price
      if (price) amount = new Money(price)
    } catch (error) {
      if (isClient && error instanceof ApolloError) {
        addToast({
          autoClose: false,
          headline: t('redeemmoney.toast.headline'),
          message: {
            translationKey: 'redeemmoney.toast.message',
            translationVariables: {
              cta: {
                text: t('redeemmoney.toast.cta'),
                to: '/account/transactions',
                type: 'link',
              },
            },
          },
        })
      }
    }

    return amount
  }

  return { redeemMoney }
}
