<template>
  <div>
    <NuxtLayout name="default">
      <MoneyBackCard />
      <WinEverCard />
      <NuxtLayout
        name="teasers"
        :no-results="!query.loading && teasers.length <= 0"
        @refresh="query.refetch"
      >
        <Component
          :is="component"
          v-for="({ component, id, props }, index) in teasers"
          :key="id"
          v-bind="props"
          :fetchpriority="index < priorityFetchAmount ? 'high' : 'low'"
          @click="onTeaserClick"
        />
      </NuxtLayout>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  footer: 'full',
  header: 'full',
  layout: false,
  middleware: ['age-verification', 'self-excluded'],
})

const { isDesktop } = useDevice()
const licensedTerritory = useLicensedTerritory({ raw: true })

const query = useTeasersQuery(
  { licensedTerritory },
  {
    nextFetchPolicy: 'cache-and-network',
    pollInterval: 3_000_000,
  },
)

const { onTeaserClick } = usePersonalizedTeasersTracking(query)

const subscription = useDrawDateChangesSubscription({ licensedTerritory })
subscription.onResult(() => query.refetch())

const priorityFetchAmount = computed(() => (isDesktop ? 6 : 3))

const teasers = computed(() => {
  const teasers = query.result.value?.teasers.teasers ?? []
  return mapToTeaserComponents(teasers, licensedTerritory, query.refetch)
})
</script>
