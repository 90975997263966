<template>
  <section v-if="amount" class="money-back-card mb-xs p-xs rounded-md">
    <h1 class="title p-md pb-xs text-h5 bg-white">
      {{ $t('moneybackcard.title') }}
    </h1>
    <i18n-t
      class="message p-md pt-none bg-white"
      keypath="moneybackcard.message"
      tag="p"
    >
      <template #amount>
        <span class="font-medium">{{ amount }}</span>
      </template>
    </i18n-t>
  </section>
</template>

<script lang="ts" setup>
const { redeemMoney } = useRedeemMoney()
const amount = await redeemMoney()
</script>

<style lang="scss" scoped>
.money-back-card {
  background:
    url('./assets/dollars-bg.svg') top right no-repeat,
    color('success600');
  background-size: 11.25rem 7.188rem;
  box-shadow: $shadow-lg;

  .title {
    border-top-left-radius: spacing('2xs');
    border-top-right-radius: spacing('2xs');
  }

  .message {
    border-bottom-right-radius: spacing('2xs');
    border-bottom-left-radius: spacing('2xs');
  }
}

@include media-desktop {
  .money-back-card {
    margin-bottom: spacing('md');
  }
}
</style>
