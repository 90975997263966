import { type UseQueryReturn } from '@vue/apollo-composable'

import {
  type Exact,
  type TeasersQuery,
} from '~/@types/generated/backend/graphql-schema-types'
import { CustomExperimentEvent } from '~/composables/tracking'

type TeasersQueryReturnType = Pick<
  UseQueryReturn<TeasersQuery, Exact<{ licensedTerritory: string }>>,
  'onResult' | 'result'
>

export const usePersonalizedTeasersTracking = (
  query: TeasersQueryReturnType,
): { onTeaserClick: () => void } => {
  const { createCustomExperimentEvent, createCustomExperimentInitEvent } =
    useTracking()

  const hasTrackedInitEvent = ref(false)

  query.onResult((result) => {
    if (!result.data) return
    if (hasTrackedInitEvent.value) return

    createCustomExperimentInitEvent({
      exp_id: CustomExperimentEvent.PersonalizedTeaser,
      exp_variant: result.data.teasers.id,
    })

    hasTrackedInitEvent.value = true
  })

  const onTeaserClick = () => {
    createCustomExperimentEvent({
      exp_goal: 'teaser_click',
      exp_id: CustomExperimentEvent.PersonalizedTeaser,
      exp_variant: query.result.value!.teasers.id,
    })
  }

  return { onTeaserClick }
}
